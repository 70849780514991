import Container from "react-bootstrap/Container";

const HomeSection = ({ innerRef }) => {
  return (
    <section
      className="py-5 section background-gray"
      ref={innerRef}
      id="introduction"
    >
      <Container>
        <h2 className="text-center">
          <strong>Introduction</strong>
        </h2>
        <p className="mt-4">
          At Innoware we have developed many solutions involving 3D. We have our
          team of modelers and the 3D that we produce are made by polygonal
          modeling, which is the method of choice for real-time computer
          graphics.
        </p>
        <p>
          A good 3D solution is often a combination of realistic looking 3D and
          code behind that connects the 3D with real data from a database. We
          have years of experience with programming and our modelers are skilled
          with the latest modeling techniques and technologies.
        </p>
        <p>
          This page is dedicated to showcase some of the possibilities and
          benefits in implementing 3D into existing and new solutions. If you
          think that your business could benefit from using 3D we can offer
          modeling and programming services and help you design the perfect 3D
          solution.
        </p>
        <p>
          Try the following examples to experience how easy it is to work with
          3D. Simply press the 3D logo to activate the demo.
        </p>
      </Container>
    </section>
  );
};

export default HomeSection;
