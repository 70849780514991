import Container from "react-bootstrap/Container";
import _3disthefuture from "../assets/images/3disthefuture5.jpg";
import realisticLooking3DModels from "../assets/images/realisticLooking3DModels.jpg";
import partIdentification from "../assets/images/partIdentification.jpg";
import explodedViews from "../assets/images/explodedViews.jpg";
import partAnnotation from "../assets/images/partAnnotation.jpg";
import animated3d from "../assets/images/animated3d.jpg";
import show3d from "../assets/images/download.png";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

const topics = [
  {
    title: "3D is the future",
    text: (
      <p>
        3D is the future and 3D technology is becoming more advanced and more
        available day by day. Our aim is to produce the best lightweight 3D ﬁles
        possible and help our clients implement the files into any existing
        application or help them design a completely new solution. Working with
        3D graphics is intuitive and captures attention. Showing objects in 3D
        allows them to change style, location, rotation, material, size, colors
        and textures. We help produce mechanical animations to show and guide
        users how to perform operations like maintenance methods, part
        replacements or assembly manuals.
      </p>
    ),
    imageUrl: _3disthefuture,
  },
  {
    title: "Realistic looking 3D models",
    text: (
      <p>
        Adding 3D to your website results in better viewing of products, a
        critical factor in selling items that online shoppers can't interact
        with physically. Products can be rotated 360 degrees. It's as if you are
        holding them right in your hand. Applying textures and realistic looking
        materials ensures the final result looks stunning. <br />
        Most 3D files are modeled from scratch using images and other technical
        information as reference. But we can also help you convert existing
        mathematical based 3D files into polygon based formats.
      </p>
    ),
    imageUrl: realisticLooking3DModels,
    sceneUrl: "/scenes/realisticLooking3DModels/index.html",
  },
  {
    title: "Part Identification",
    text: (
      <p>
        3D is great for part identiﬁcation. Unlimited zoom on assemblies or
        individual parts helps you isolate, select and order the correct part
        the ﬁrst time every time.
        <br />
        Move the mouse over any part and instantly see its information. Isolate
        a part by selecting it. Rotate and zoom on any part to verify that you
        have found the part you are looking for, This is especially helpful for
        assemblies containing many similar parts.{" "}
      </p>
    ),
    imageUrl: partIdentification,
    sceneUrl: "/scenes/partIdentification/index.html",
  },
  {
    title: "Exploded views",
    text: (
      <p>
        Exploded views enable the user to watch objects disassemble and reveal
        internal parts. Select the internal part to see it isolated. Internal
        parts are normally preloaded but can also be loaded on the fly and
        create the effect of an intuitive drill down navigation where more
        details are loaded as needed. The possibilities are endless.
      </p>
    ),
    imageUrl: explodedViews,
    sceneUrl: "/scenes/explodedViews/index.html",
  },
  {
    title: "Highlight parts",
    text: (
      <p>
        Change color on parts to indicate a selection level. This is helpful to
        illustrate which parts need attention and the state of the part. Often
        used for maintenance guidance or illustration of damage level. Attach
        free text to any part to clearly inform colleges about your observations
        and instructions.{" "}
      </p>
    ),
    imageUrl: partAnnotation,
    sceneUrl: "/scenes/partAnnotation/index.html",
  },
  {
    title: "Animated 3D",
    text: (
      <p>
        Animated 3D can be used for more effective training or manuals. With
        animated 3D it's easy to illustrate complex mechanical procedures or
        assembly steps. Interactive 3D combined with animations helps users
        solve problems themselves, reducing the time for expensive support.{" "}
        <br />
        Using text to speech for audio helps the user to keep focus on the
        animation instead of reading the guidance.{" "}
      </p>
    ),
    imageUrl: animated3d,
    sceneUrl: "/scenes/animated3d/index.html",
  },
  // {
  //   title: "Augmented Reality",
  //   text: (
  //     <p>
  //       Augmented Reality creates unique digital experiences that blend the best
  //       of digital and physical worlds. Also, no special hardware or software is
  //       needed to try the experience. <br />
  //       Try it yourself, scan the QR Code with any modern handheld device and
  //       experince AR right now. Discover the benefits yourself, see how it
  //       creates customer confidence, shows dimensions and gives designers the
  //       chance to show of ideas in an early design stage.
  //     </p>
  //   ),
  //   imageUrl: "/images/augmentedreality.jpg",
  //   link: "http://www.google.com",
  // },
];

const Why3dSection = ({ innerRef }) => {
  const [show, setShow] = useState(false);
  const [topic, setTopic] = useState();

  return (
    <section
      className="py-5 section background-white"
      ref={innerRef}
      id="why3d"
    >
      <Container>
        <h2 className="text-center">
          <strong>Why 3D</strong>
        </h2>
        {topics.map((t, index) => {
          return (
            <div key={index} className="my-5">
              <div className="container">
                <div
                  className={`row align-items-center ${
                    index % 2 === 1 ? "flex-row-reverse" : ""
                  }`}
                >
                  <div className="col-12 col-lg">
                    <div className="text-wrapper">
                      <h3>
                        <strong>{t.title}</strong>
                      </h3>
                      {t.text}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div
                      className="image-wrapper"
                      style={{
                        width: "100%",
                        height: "300px",
                        borderWidth: "1px",
                        borderStyle: "dashed",
                        borderColor: "#CCCCCC",
                        position: "relative",
                      }}
                    >
                      {t.imageUrl && (
                        <img
                          src={t.imageUrl}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          className={t.sceneUrl ? "clickable" : ""}
                          alt={t.title}
                          onClick={() => {
                            if (!t.sceneUrl) return;
                            setTopic(t);
                            setShow(true);
                          }}
                        />
                      )}

                      {t.imageUrl && t.sceneUrl && (
                        <div
                          style={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            pointerEvents: "none",
                          }}
                        >
                          <img src={show3d} style={{ width: 40, height: 40 }} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Container>
      <Modal show={show} onHide={() => setShow(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{topic?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <iframe
            style={{ width: "100%", height: "100%" }}
            src={topic?.sceneUrl}
          ></iframe>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Why3dSection;
