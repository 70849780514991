import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Scrollspy } from "@makotot/ghostui";
import logo from "../assets/images/logo2.svg";
import { useState } from "react";

const sections = [
  { name: "Intro", id: "introduction" },
  { name: "Why 3D", id: "why3d" },
  { name: "Services", id: "services" },
  //{ name: "About Us", id: "about" },
  { name: "Contact", id: "contact" },
];

const HeaderControl = ({ sectionRefs }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Navbar
      bg="dark"
      data-bs-theme="dark"
      expand="lg"
      fixed="top"
      onToggle={() => setIsExpanded(!isExpanded)}
      expanded={isExpanded}
    >
      <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <Container>
            <Navbar.Brand>
              <img src={logo} className="company-logo" alt="Innoware" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                {sections.map((s, index) => (
                  <Nav.Link
                    key={s.id}
                    href={`#${s.id}`}
                    ref={sectionRefs[index]}
                    onClick={() => setIsExpanded(false)}
                    className={
                      currentElementIndexInViewport === index ? "active2" : ""
                    }
                  >
                    {s.name}
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        )}
      </Scrollspy>
    </Navbar>
  );
};

export default HeaderControl;
