const settings = {
  company: {
    name: "Innoware S.A. de C.V.",
    street: "Laos 814",
    zip: "66059",
    city: "General Escobedo",
    country: "Mexico",
    countryCode: "MX",
    emails: {
      contact: "contact@innoware.mx",
    },
    //phone: "+45 24 428 428",
    openingHours: "Mon - Fri: 9 - 18",
    gps: { lat: 25.5918681, lng: -100.2599926 },
  },
  google: {
    maps: {
      key: "AIzaSyCz6xzIYFLU8B-lxlbgn4Mwpa-Gov7aNlk",
      //key: "AIzaSyBe_7G3xsv0QeJw_KC80JjKZg-6Vdr8rGc",
    },
  },
};

export default settings;
