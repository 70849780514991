import Container from "react-bootstrap/Container";

const ServicesSection = ({ innerRef }) => {
  return (
    <section
      className="py-5 section background-gray"
      ref={innerRef}
      id="services"
    >
      <Container>
        <h2 className="text-center">
          <strong>Services</strong>
        </h2>
        <div className="row mt-4">
          <div className="col background-white m-3 p-4">
            <h3>
              <strong>Modeling</strong>
            </h3>
            <p>
              We are a high volume - low cost modeling service provider. We help
              develop the infrastructure between our companies so work orders
              and 3D files transfer with ease.
            </p>
          </div>
          <div className="col background-white m-3 p-4">
            <h3>
              <strong>Animation</strong>
            </h3>
            <p>
              We do mechanical animations, with multi language text to speech
              guidance. These are animations used to explain mechanical
              procedures and not animation used in movies or video games.
            </p>
          </div>
          <div className="col background-white m-3 p-4">
            <h3>
              <strong>Programming</strong>
            </h3>
            <p>
              We have years of 3D programming experience and look forward to
              helping design the perfect 3D solution for you. We can link our 3D
              to any information you might already have; part number,
              description, prices and so on.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ServicesSection;
