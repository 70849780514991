const FooterControl = () => {
  return (
    <div
      style={{ backgroundColor: "#212529", height: 100, color: "white" }}
      className="d-flex justify-content-center align-items-center"
    >
      © Copyright 2020 Innoware. All Rights Reserved.
    </div>
  );
};

export default FooterControl;
