import Container from "react-bootstrap/Container";
import settings from "../utils/settings";
import GoogleMapReact from "google-map-react";

const ContactSection = ({ innerRef }) => {
  const center = settings.company.gps;
  const zoom = 6;

  const handleApiLoaded = (map, maps) => {
    //const image = "/assets/marker11.png";

    new maps.Marker({
      position: center,
      map,
      //icon: image,
    });
  };

  return (
    <section
      className="py-5 section background-white"
      ref={innerRef}
      id="contact"
    >
      <Container>
        <h2 className="text-center">
          <strong>Contact</strong>
        </h2>
        <div className="row mt-5">
          <div className="col" style={{ minHeight: 300 }}>
            <p>
              Innoware is located in Monterrey, Mexico. We focus on 3D
              programming and high volume - low cost 3D modeling.
            </p>
            <p>
              If you have any questions please feel free to contact us at:{" "}
              <a href={`mailto:${settings.company.emails.contact}`}>
                {settings.company.emails.contact}
              </a>
            </p>
          </div>
          <div className="col">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: settings.google.maps.key,
              }}
              defaultCenter={center}
              defaultZoom={zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            ></GoogleMapReact>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactSection;
