import "./App.css";

import HeaderControl from "./header.control";
import FooterControl from "./footer.control";
import IntroSection from "./intro.section";
import Why3dSection from "./why3d.section";
import ServicesSection from "./services.section";
import AboutSection from "./about.section";
import ContactSection from "./contact.section";

import banner from "../assets/images/banner8.jpg";
import { useRef } from "react";

const BannerControl = () => {
  return (
    <div>
      <div style={{ position: "relative" }}>
        <img
          src={banner}
          style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
          }}
          className="text-center"
        >
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              color: "white",
              margin: "auto",
              borderRadius: 10,
              display: "inline-block",
              padding: 10,
              paddingLeft: 50,
              paddingRight: 50,
            }}
          >
            <div style={{ fontSize: 34 }}>A 3D solution provider</div>
            <div>With focus on designing the best solution for you</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function App() {
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  return (
    <div>
      <HeaderControl sectionRefs={sectionRefs} />

      <BannerControl />

      <main>
        <IntroSection innerRef={sectionRefs[0]} />
        <Why3dSection innerRef={sectionRefs[1]} />
        <ServicesSection innerRef={sectionRefs[2]} />
        <ContactSection innerRef={sectionRefs[3]} />
      </main>

      <FooterControl />
    </div>
  );
}

export default App;
